.HomeSection{
    flex: 1;
    flex-grow: 1;
}
.HomeSection__container{
    width: 43.75vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    text-align: center;
}
.HomeSection__containerLarge{
    width: 87.5vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    text-align: center;
}
.HomeSection__text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 0.4rem;
    color: white;
    width: 100%;
}
.HomeSection__text p::selection{
    color: #00ffff;
}
.HomeSection__slider{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.HomeSection__image{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    touch-action: manipulation;
    will-change: transform;
}

@media (max-width: 768px){
    .HomeSection__container{
        width: 100vw;
        height: 92svh;
    }
    .HomeSection__containerLarge{
        width: 100vw;
        height: 92svh;
    }
    .HomeSection__text{
        font-size: 0.825rem;
    }
}