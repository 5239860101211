.Contact{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Contact__form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-top: 10vh;
}
.Contact__form p::selection{
    color: #00ffff;
}
.Contact__form p{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
}
.Contact__form input{
    margin: 5px 0px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: #2e2e2d;
    padding: 5px;
    border: #2e2e2d 1px solid;
    width: 30%;
    margin: 10px 0px;
}
.Contact__form textarea{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    resize: none;
    font-style: normal;
    font-size: 1rem;
    width: 30%;
    height: 200px;
    margin: 10px 0px;
    padding: 5px;
    border: #2e2e2d 1px solid;
}
.Contact__form input::selection{
    color: white;
    background-color: #2e2e2d;
}
.Contact__form textarea::selection{
    color: white;
    background-color: #2e2e2d;
}
::placeholder {
    color: #2e2e2d;
    opacity: 1;
  }
  
::-ms-input-placeholder {
    color: #2e2e2d;
  }

input[type=submit]{
    width: 5vw;
    text-decoration: none;
    margin: 20px 0px;
    cursor: pointer;
    border: #2e2e2d 1px solid;
    background-color: white;
}
input[type=submit]:hover{
    background-color: #2e2e2d;
    opacity: 0.75;
    color: white;
}
.Contact__thanks{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    resize: none;
    font-style: normal;
    font-size: 2rem;
    color: #2e2e2d;
}
.Contact__thanks::selection{
    color:#00ffff;
}

@media (max-width: 1000px){
    input[type=submit]{
        width: 10vw;
    }
}

@media (max-width: 768px) {

    .Contact__form{
        padding-top: 5vh;
    }

    .Contact__form p{
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
    }
    .Contact__form input{
        margin: 5px 5px;
        width: 70%;
        border-radius: 0px;

    }
    .Contact__form textarea{
        width: 70%;
        margin: 5px 0px;
        border-radius: 0px;
    }
    input[type=submit]{
        width: 40%;
        margin: 15px 0px;
        cursor: pointer;
        border: #2e2e2d 1px solid;
        background-color: white;
        border-radius: 0px;
    }

}