.Header{
    line-height: 0.2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    padding-left: 10px;
}
.Header-title,
.Header-subtitle p{
    max-width: 100%;
    text-justify: inter-word;
    text-overflow: clip;
}
.Header-title{
    font-weight: 600;
    font-size: 1.15rem;
    color: #2e2e2d;
    text-decoration: none;
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none;
    letter-spacing: 0.1ch;
}
.Header-subtitle{
    font-size: 0.9rem;
    color: #2e2e2d;
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none;
    letter-spacing: 0.33ch;
}

@media (max-width: 768px){
    .Header{
        line-height: 0.1rem;
    }
    .Header-title{
        font-size: 1rem;
    }
    .Header-subtitle{
        font-size: 0.8rem;
    }
}