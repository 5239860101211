.App{
  display: grid;
  grid-template-columns: 1fr 7fr;
}

.Left{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  width: 100%;
  top: 0;
  position: sticky;
  position: -webkit-sticky;
}

@media (max-width: 768px){
  .App{
    display: flex;
    flex-direction: column;
  }
  .Left{
    display: flex;
    flex-direction: row;
    height: 8vh;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    text-justify: justify;
    top: 0;
    position: sticky;
    z-index: 1000;
    background-color: white;
  }
}