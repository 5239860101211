.Video{
    width: 896px;
    height: 504px;
}
@media (max-width: 1024px){
    .Video{
        width: 840px;
        height: 472.5px;
    }
}
@media (max-width: 768px){
    .Video{
        width: 80vw;
        height: 45vh;
    }
}