.Menu > * {
    list-style-type: none;
    padding-inline-start: 0px;
}
.Menu > * :hover{
    font-style: italic;
}
.Menu > * ::selection{
    color: #00ffff;
}
.Menu-item{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.85rem;
    color: #2e2e2d;
    text-decoration: none;
    padding-left: 10px;
}
.Menu-toggle {
    display: none;
}

@media (max-width: 768px) {
    .Menu{
        flex-direction: column;
    }
    .Menu-list-open{
        display: none;
    }
    .Menu-list {
        display: none;
    }
    .Menu-list li{
        margin-bottom: 3px;
    }
    .bm-burger-button {
        position: fixed;
        width: 25px;
        height: 15px;
        right: 10px;
        top: 15px;
    }
    
    .bm-burger-bars {
        background: #2e2e2d;
    }

    .bm-burger-bars-hover {
        background: #2e2e2d;
    }
    
    .bm-cross-button {
        width: 25px;
        height: 15px;
        right: 10px;
        top: 10px;
    }
    
    .bm-cross {
        background: #2e2e2d;
    }
    
    .bm-menu-wrap {
        position: fixed;
        height: 100vh !important;
        max-width: 250px;
        width: 55vw !important;
        top: 0;
    }
    
    .bm-menu {
        background: white;
        font-size: 1.15em;
        overflow-y: hidden !important; 
        overflow-x: hidden !important; 
    }
    
    .bm-morph-shape {
        fill: #2e2e2d;
    }
    
    .bm-item-list {
        color: #2e2e2d;
        padding: 8.5vh 0px 0px 10px;
        height: 100%;
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
    }

    .bm-item {
        text-decoration: none;
        list-style-type: none;
        padding-inline-start: 0px;
        margin: 0px !important;
    }

    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
    .Menu__open {
        transition: opacity 0.4s ease-in-out;
        opacity: 1;
    }

    .Menu__closed {
        opacity: 0;
    }

    .Menu__footer{
        width: 100%;
        padding-left: 10px;
        padding-bottom: 21vh;
    }
    .Menu__footer p{
        margin-bottom: 15px;
    }
    .Menu__footer > * ::selection{
        color: #00ffff;
    }
    .Menu__footer > * :hover{
        font-style: italic;
    }
    .Menu__footer-item{
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 0.85rem;
        line-height: 0.5rem;
        text-align: left;
    }
    .Menu__footer-item::selection{
        color: #00ffff;
    }
    .Menu__footer-item a{
        color: #2e2e2d;
        text-decoration: none;
    }
    
    .Menu__footer-item-list{
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 0.85rem;
        list-style-type: none;
        padding-inline-start: 0px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        line-height: 0.4rem;
    }
    .Menu__footer-item-list a{
        color: #2e2e2d;
        text-decoration: none;
        font-size: 0.85rem;
        padding-right: 15px;
    }

}
@media (max-width: 450px){
    .bm-burger-bars {
        margin-top: 1vh;
    }
    .bm-cross-button {
        margin-top: 1vh;
        margin-right: 1vw;
        transform: scale(1.3);
    }
}
@media (max-width: 375px){
    .bm-item-list {
        padding: 8.5vh 0px 0px 0px;
    }
}