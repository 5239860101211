.Footer{
    width: 100%;
    padding-left: 10px;
}

.Footer > * ::selection{
    color: #00ffff;
}
.Footer > * :hover{
    font-style: italic;
}
.Footer-item{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.85rem;
    line-height: 0.4rem;
    text-align: left;
}
.Footer-item::selection{
    color: #00ffff;
}
.Footer-item a{
    color: #2e2e2d;
    text-decoration: none;
}
.Footer-item__list{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.85rem;
    list-style-type: none;
    padding-inline-start: 0px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    line-height: 0.4rem;
}
.Footer-item__list a{
    color: #2e2e2d;
    text-decoration: none;
    font-size: 0.85rem;
    padding-right: 15px;
}
.Footer-item_list a:last-child{
    padding-right: 0px;
}

@media (max-width: 768px){
    .Footer{
        display: none;
    }
}
